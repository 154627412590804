import { useSessionStorage } from ".";

export const useLoggedIn = () => {
  const initial = { url: null, key: null }

  const [credentials, setCredentials] = useSessionStorage("auth", initial)

  const isLoggedIn = credentials?.url !== null && credentials?.key !== null;


  const logout = () => setCredentials(initial)


  return { setCredentials, credentials, isLoggedIn, logout }
}