

import React, { useEffect, useState } from "react"
import { formatDate } from "../utils"

export const augmentMovie = ({ title, link, isoDate, contentSnippet, content }) => {

  const [titleYear, stars] = title.split(" - ")
  const lastComma = titleYear.lastIndexOf(',')
  const year = titleYear.substring(lastComma + 1).trim()
  const realTitle = titleYear.substring(0, lastComma)

  const imageRegex = /https(.*).\?/g
  const imageStuff = content.match(imageRegex)

  const image = imageStuff ? imageStuff.pop().split("?")[0] : "";
  let review;
  if (!contentSnippet.includes("Watched on")) {
    review = contentSnippet
  }

  const returnables = {
    title: realTitle, year, stars, date: formatDate(isoDate), link, image, review
  }

  return returnables
}


export const useFetchRecentlySeenMovies = ({ preloadFeed }) => {
  const withoutLists = preloadFeed.filter(i => !i.link.includes("stevesux/list"))

  const [movies, setMovies] = useState(withoutLists.map(augmentMovie))

  useEffect(() => {
    async function fetchData() {
      const data = await fetch('api/letterboxd')
        .then(response => response.json())

      const augmentedMovies = data.items.map(augmentMovie)
      setMovies(augmentedMovies.filter(i => !i.link.includes("stevesux/list")))
    }
    fetchData();

  }, [])

  return movies;
}