import { useState, useEffect } from 'react';

const isBrowser = typeof window !== "undefined"

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = isBrowser && sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function useSessionStorage(key, defaultValue) {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    if (isBrowser) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
}