import { createClient } from '@supabase/supabase-js'
import { useEffect, useState } from "react"


export const useSupabase = ({ url, key }) => {

  const [supabase, setSupabase] = useState(null)

  useEffect(() => {
    if (url, key) {

      setSupabase(createClient(url, key))
    }
  }, [url, key])

  return supabase;
}